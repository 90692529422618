$font-family: 'Inter', sans-serif;
$font-size: 16px;
$header-height: 50px;
$footer-height: 54px;
$input-font-family: $font-family;
$font-dark-color: #111;
$font-default-color: #333;
$font-light-color: #fff;
$font-faded-color: rgba(0, 0, 0, 0.4);
$background-color: #fff;
$background-light: #f7f7fa;
$background-dark: #f2f2f7;
$border-color: #e2e2e2;
$placeholder-color: #7f7f7f;
$placeholder-light-color: #c7c7c7;
$link-color: #007aff;
$link-active-color: lighten($link-color, 25%);
$link-color2: #007aff;
$link-active-color2: lighten($link-color2, 25%);
$link-color3: #2979ff;
$thin-line-color: rgba(0, 0, 0, 0.1);
$rough-shadow-color: rgba(0, 0, 0, 0.3);
$rough-light-color: rgba(255, 255, 255, 0.3);
$thin-white-line-color: rgba(255, 255, 255, 0.1);
$primary: #007aff;
$secondary: #7f7f7f;
$danger: #dc5960;
$warning: #e9e2d2;
$success: #3eb991;
$mobile-breakpoint: 767px;
$tablet-landscape-breakpoint: 1200px;
$tablet-portrait-breakpoint: 1023px;
$default-transition: 0.3s ease;
$quicker-transition: 0.2s ease;
$quick-transition: 0.15s ease;
$x-quick-transition: 0.1s ease;
$scale-transition: 0.3s ease;
$bezier-transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);

$status-new: #2f80ed;
$status-processing: #f2c94c;
$status-accepted: #219653;
$status-rejected: #eb5757;
