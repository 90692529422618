.root {
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.item {
    font-weight: bold;
}

.item + .item {
    margin-top: 17px;
}
